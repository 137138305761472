import React from "react"
import { ResponsiveProvider } from "./src/contexts/responsive-context"

export const wrapRootElement = ({ element }) => (
  <ResponsiveProvider>{element}</ResponsiveProvider>
)

export const onRouteUpdate = ({ location }) => {
  const trackingId = window.GA_TRACKING_ID
  if (!trackingId || typeof window.gtag !== `function`) {
    return
  }

  let locationStr = ""

  let pathname = location.pathname
  if (!pathname.endsWith("/")) {
    pathname += "/"
  }

  if (location) {
    locationStr = `${pathname}${location.search}${location.hash}`
  }

  window.gtag("config", trackingId, {
    page_path: locationStr,
  })
}
