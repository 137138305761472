import React from "react"
import { useMediaQuery } from "react-responsive"

const defaultContext = {
  isSmallMobile: false,
  isMobile: false,
  isWidescreen: true,
}

export const ResponsiveContext = React.createContext(defaultContext)

const IPHONE_5_SCREEN_WIDTH = 640
const MOBILE_BREAKPOINT_FROM_BULMA = 768
const DESKTOP_BREAKPOINT_FROM_BULMA = 1024
const WIDESCREEN_BREAKPOINT_FROM_BULMA = 1216

function ResponsiveProvider(props) {
  const { children } = props

  const isSmallMobile = useMediaQuery({
    maxWidth: IPHONE_5_SCREEN_WIDTH,
  })

  const isMobile = useMediaQuery({
    maxWidth: MOBILE_BREAKPOINT_FROM_BULMA,
  })

  const isTouch = useMediaQuery({
    maxWidth: DESKTOP_BREAKPOINT_FROM_BULMA,
  })

  const isWidescreen = useMediaQuery({
    minWidth: WIDESCREEN_BREAKPOINT_FROM_BULMA,
  })

  return (
    <ResponsiveContext.Provider
      value={{
        isSmallMobile: isSmallMobile,
        isMobile: isMobile,
        isTouch: isTouch,
        isWidescreen: isWidescreen,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  )
}

export default ResponsiveContext
export { ResponsiveProvider }
